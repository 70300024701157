import React from "react";
import Table1 from "../components/Table1";

const Admin = () => {
  return (
    <>
      <Table1 />
    </>
  );
};

export default Admin;
