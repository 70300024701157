import React from "react";
import { Box, IconButton } from "@mui/material";
// import FirstPageIcon from "@mui/icons-material/FirstPage";
import { MdFirstPage, MdLastPage, MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
// import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
// import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

const AdminTable = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  //First page action
  const firstPage = (e) => {
    e.preventDefault();
    onPageChange(e, 0);
  };
  //prev button action
  const backButton = (e) => {
    e.preventDefault();
    onPageChange(e, page - 1);
  };
  //next button action
  const nextButton = (e) => {
    e.preventDefault();
    onPageChange(e, page + 1);
  };
  //last page action
  const lastPage = (e) => {
    e.preventDefault();
    onPageChange(e, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={firstPage}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <MdLastPage /> : <MdFirstPage />}
      </IconButton>
      <IconButton
        onClick={backButton}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <MdKeyboardArrowRight />
        ) : (
          <MdKeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={nextButton}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <MdKeyboardArrowLeft />
        ) : (
          <MdKeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={lastPage}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <MdFirstPage /> : <MdLastPage />}
      </IconButton>
    </Box>
  );
};

AdminTable.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default AdminTable;
