import React from "react";
import { usePaystackPayment } from "react-paystack";
import { stack } from "../utils/backend";

const Paystack = () => {
  const configPay = {
    reference: new Date().getTime().toString(),
    email: "abalogun@sterlingtech.com.ng",
    amount: 20000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: stack,
    text: "Buy Now",
  };

  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const PaystackHookExample = () => {
    const initializePayment = usePaystackPayment(configPay);
    return (
      <div>
        <button
          onClick={() => {
            initializePayment(onSuccess, onClose);
          }}
        >
          Pay with Paystack
        </button>
      </div>
    );
  };
  return (
    <div>
      {/* display of demo of the paystack button */}
      <PaystackHookExample />
    </div>
  );
};

export default Paystack;
